var CryptoJS = require("crypto-js");
//--------------------- watchit server ------------------------------------
export const SUBSCRIPTION_URL = "http://44.226.84.16:8081";
export const SUBSCRIPTION_PROJECT_ID = "1";
export const SUBSCRIPTION_SECRET_KEY = "699cc2ea457a769f2ee58537d60f03";

// export const SUBSCRIPTION_URL = "http://watchit.khaleef.com:9000";
// export const SUBSCRIPTION_PROJECT_ID = "1";
// export const SUBSCRIPTION_SECRET_KEY = "ae118a4438307306a4f24923f537a3";
//--------------------- end watchit server --------------------------------

//--------------------- content server ------------------------------------
export const CONTENT_URL = "http://solutionsmodo.com";
export const CONTENT_PROJECT_ID = "14";
export const CONTENT_SECRET_KEY = "850910473a4a9970eb0d8aaca82715";
export const PLAYER_SCRIPT_URL =
  "https://cdn.jwplayer.com/libraries/Gu7DBc3s.js";
//--------------------- end content server ---------------------------------

//--------------------- subscriptaion server ------------------------------------
export const TELCO = "telenor";
export const CODE = "966";
export const medium = "Khaleef";
export const PARTNER_ID = 2;
export const CAMPAIGN_ID = 18;
export const KEY = "secret is cnh!@dm!n key";

// export const KNECT_SUBSCRIPTION_URL =

// "https://k8s.cricwick.net/general-subscription-production";
//export const KNECT_SUBSCRIPTION_URL = "http://localhost:4004";
//export const KNECT_SUBSCRIPTION_URL = "http://15.184.61.156:4004";
export const KNECT_SUBSCRIPTION_URL = "http://sub.watchit.mobi";
export const KNECT_SUBSCRIPTION_URL_VIRGIN =
  "http://44.224.168.120:8078/knectapi/";
export const KNECT_SUBSCRIPTION_SERVICE_ID = 56;
export const KNECT_SUBSCRIPTION_HEADER = {
  "Content-Type": "application/json",
  Authorization: "Basic d2F0Y2hpdDpLJDRHRDxNfU1oJD83ImUq",
};
//--------------------- end subscriptaion server ---------------------------------

//--------------------- encryptParam & decryptParam -----------------------------------------------
export const decryptParam = (param) => {
  var data = param;
  var bytes = CryptoJS.AES.decrypt(data, KEY);
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};

export const encryptParam = (param) => {
  var data = param;
  var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), KEY).toString();
  return encodeURIComponent(ciphertext);
};

export const getErrorObj = (message) => {
  return {
    status: false,
    message: message,
  };
};

export const getNumbervalidObj = (initial, prefix, number) => {
  return {
    status: true,
    initial: initial,
    prefix: prefix,
    number: number,
  };
};

export const getUTMMediun = () => {
  return medium;
};
//--------------------- end encryptParam & decryptParam -----------------------------------------------
