import React, { Component, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "./App.css";
//********** Constants  **********//

import {
  SUBSCRIPTION_URL,
  SUBSCRIPTION_PROJECT_ID,
  SUBSCRIPTION_SECRET_KEY,
  TELCO,
  CODE,
  KNECT_SUBSCRIPTION_URL,
  KNECT_SUBSCRIPTION_SERVICE_ID,
  KNECT_SUBSCRIPTION_HEADER,
  decryptParam,
} from "../src/Constants";

//********** Components  **********//

import TopNavbar from "./Components/TopNavbar";
import Home from "./Components/Home";
import Dramas from "./Components/Dramas";
import Telefilms from "./Components/Telefilms";
import Play from "./Components/Play";
import NewRelease from "./Components/NewRelease";
import Search from "./Components/Search";
import Profile from "./Components/Profile";
import VideoSongs from "./Components/VideoSongs";
import Footer from "./Components/Footer";
import Privacy from "./Components/Privacy";
import ScrollToTop from "./Components/ScrollToTop";
import Category from "./Components/Category";
import Unsubscribe from "./Components/Unsubscribe";

class App extends Component {
  constructor(props) {
    super(props);
    let userObj = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : {};
    let isUserLoggedIn = Object.keys(userObj).length === 0 ? false : true;

    this.state = {
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : {},
      loggedIn: isUserLoggedIn,
      lang: localStorage.getItem("lang") || "", // for en we dont need any type
      view_lists: []
    };
    this.setUser = this.setUser.bind(this);
    this.setLang = this.setLang.bind(this);
    this.setAlert1 = this.setAlert1.bind(this);
    this.setViewLists = this.setViewLists.bind(this);
  }

  componentWillMount() {
    let url = new URL(window.location.href);
    let number = new URLSearchParams(window.location.search).get(
      "subscriptionNo"
    );
    let msisdn = url.searchParams.get("msisdn");

    if (number) {
      console.log("App test------");
      console.log(number);
      this.checkORCreateUser(TELCO, CODE, number);
      console.log("App test------");
    } else {
      if (msisdn) this.getUserDetails(msisdn);
    }
  }

  componentDidMount() {
    if (this.state.lang === "_ur") {
      return import("./App-rtl.css");
    }
  }

  setUser(loggedInUser, loggedInStatus, isComingFromUnsub = false) {
    this.setState({ user: loggedInUser, loggedIn: loggedInStatus });
    console.log("User login", this.state.user);
    localStorage.removeItem("user");
    localStorage.setItem("user", JSON.stringify(loggedInUser));
    if (isComingFromUnsub) {
      window.location.href = "/";
    }
  }

  async unsubscribeUser() {
    let phone = this.userData.msisdn;
    let serviceId = localStorage.getItem("telco")
    ? JSON.parse(localStorage.getItem("telco"))
    : '56';
    console.log(phone);
    //let url = `${SUBSCRIPTION_URL}/telco/${TELCO}/unsubscribe?msisdn=${phone}&telco=${TELCO}&project_id=${SUBSCRIPTION_PROJECT_ID}&secret_key=${SUBSCRIPTION_SECRET_KEY}&web_user=1`;
    let url = `${KNECT_SUBSCRIPTION_URL}/api/v1/unsubSubscription`;
    let resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic d2F0Y2hpdDpLJDRHRDxNfU1oJD83ImUq",
      },
      body: JSON.stringify({
        msisdn: `${phone.slice(0, phone.length)}`,
        serviceId: serviceId
      }),
    });
    //// let resp = await fetch(url);
    let respObj = await resp.json();
    console.log(`unsub --> `, respObj);
    if (respObj.success) {
      if (respObj.endPoint && respObj.endPoint.length > 0) {
        window.open(respObj.endPoint, "_self");
      } else {
        this.setUser({}, false);
      }
      
    }
  }

  setLang(newLang) {
    localStorage.setItem("lang", newLang);
    window.location.reload();
  }

  setViewLists(list) {
    console.log("lit", list);
    this.setState({ view_lists: list });
  }

  async getUserDetails(number) {
    let url1 = `${KNECT_SUBSCRIPTION_URL}/api/v1/checkSubscriptionStatusByMSISDN?msisdn=${`${number.slice(
      0,
      number.length
    )}`}&productId=2`;
    let resps = await fetch(url1, {
      method: "GET",
      headers: KNECT_SUBSCRIPTION_HEADER,
    });

    let respObjs = await resps.json();

    console.log(respObjs.subscription.status);
    if (respObjs.subscription.status === "active") {
      this.setUser(respObjs.subscription, true);
    }
  }

  // async getUser(telco, code, number) {
  //   try {
  //     const url = `${SUBSCRIPTION_URL}/api/user/find_sub_by_phone?web_user=1&telco=${telco}&project_id=${SUBSCRIPTION_PROJECT_ID}&secret_key=${SUBSCRIPTION_SECRET_KEY}&phone=${number}`;
  //     let resp = await fetch(url);
  //     let respObj = await resp.json();

  //     if (
  //       respObj.status === 1 &&
  //       (respObj.user.subscription.status === 1 ||
  //         respObj.user.subscription.status === 3)
  //     ) {
  //       this.setUser(respObj.user, true);
  //     }
  //   } catch (e) {}
  // }
  async getUser(telco, code, number) {
    try {
      // const url = `${DRAMATIME_URL}/api/user/find_sub_by_phone?web_user=1&telco=${telco}&project_id=${DT_PROJECT_ID}&secret_key=${DT_SECRET_KEY}&phone=${number}`;
      // let resp = await fetch(url);
      // let respObj = await resp.json();

      let url = `${KNECT_SUBSCRIPTION_URL}/api/v1/checkUserStatus?msisdn=${`${number}`}`;

      let resp = await fetch(url, {
        method: "GET",
        headers: KNECT_SUBSCRIPTION_HEADER,
      });

      let respObj = await resp.json();
      // console.log(respObj.data.subscription);

      if (
        respObj.data.status === 1 &&
        (respObj.data.subscription === 1 || respObj.data.subscription === 3)
      ) {
        // if (
        //   respObj.status === 1 &&
        //   (respObj.user.subscription.status === 1 ||
        //     respObj.user.subscription.status === 3)
        this.setUser(respObj.data, true);
      }
    } catch (e) {}
  }

  async sendPin(telco, code, number) {
    localStorage.setItem("telco", telco);
    try {
      const url = `${KNECT_SUBSCRIPTION_URL}/api/v1/genAuthCode`;
      let resp = await fetch(url, {
        method: "POST",
        headers: KNECT_SUBSCRIPTION_HEADER,
        body: JSON.stringify({
          msisdn: `${number.slice(0, number.length)}`,
          serviceId: "2",
        }),
      });

      let respObj = await resp.json();
      console.log("Send pin resp ->", respObj);
      if (respObj.success) {
      } else {
      }
    } catch (e) {}
  }

  async checkORCreateUser(telco, code, number) {
    let url = `${KNECT_SUBSCRIPTION_URL}/api/v1/checkSubscriptionStatus?msisdn=${`${number.slice(
      0,
      number.length
    )}`}&serviceId=${KNECT_SUBSCRIPTION_SERVICE_ID}`;

    let resp = await fetch(url, {
      method: "GET",
      headers: KNECT_SUBSCRIPTION_HEADER,
    });

    let respObj = await resp.json();
    console.log(respObj);
    console.log("user Status ->", respObj.success, respObj.response_code);

    if (
      respObj.success &&
      (respObj.response_code === 114 || respObj.response_code === 110)
    ) {
      this.getUser(telco, code, number);
    }
  }
  setAlert1(newAlert) {}

  render() {
    return (
      <div>
        {
          <Router>
            <div className="container-fluid">
              <div className="row">
                <div className="col pl-0 pr-0 wrapper">
                  <TopNavbar
                    props={this.props}
                    unsubscribeUser={this.unsubscribeUser}
                    //  setAlert={this.setAlert}
                    setAlert1={this.setAlert1}
                    setViewLists={this.setViewLists}
                    isUser={this.state.loggedIn}
                    setUser={this.setUser}
                    userData={this.state.user}
                    setLang={this.setLang}
                    lang={this.state.lang}
                  />
                  {/* <button onClick={() => this.setState({ show: true })}>
                    Alert
                  </button> */}
                  {/* <SweetAlert
                    //  show={this.state.setAlert}
                    // showMe={this.showMe}
                    title="Demo"
                    text="SweetAlert in React"
                    onConfirm={() => this.setState({ show: false })}
                  /> */}
                  <Route
                    exact
                    path="/"
                    render={(props) => (
                      <Home
                        {...props}
                        lang={this.state.lang}
                        viewLists={this.state.view_lists}
                      ></Home>
                    )}
                  />
                  <Route
                    exact
                    path="/dramas"
                    render={(props) => (
                      <Dramas {...props} lang={this.state.lang}></Dramas>
                    )}
                  />
                  <Route
                    exact
                    path="/telefilms"
                    render={(props) => (
                      <Telefilms {...props} lang={this.state.lang}></Telefilms>
                    )}
                  />
                  <Route
                    exact
                    path="/video-songs"
                    render={(props) => (
                      <VideoSongs
                        {...props}
                        lang={this.state.lang}
                      ></VideoSongs>
                    )}
                  />
                  <Route
                    exact
                    path="/newrelease"
                    render={(props) => (
                      <NewRelease
                        {...props}
                        lang={this.state.lang}
                      ></NewRelease>
                    )}
                  />
                  <Route
                    exact
                    path="/search"
                    render={(props) => (
                      <Search {...props} lang={this.state.lang}></Search>
                    )}
                  />
                  <Route
                    exact
                    path="/play"
                    render={(props) => (
                      <Play
                        {...props}
                        isUser={this.state.loggedIn}
                        userData={this.state.user}
                        setUser={this.setUser}
                        lang={this.state.lang}
                      ></Play>
                    )}
                  />
                  <Route
                    exact
                    path="/profile"
                    render={(props) => (
                      <Profile
                        {...props}
                        isUser={this.state.loggedIn}
                        userData={this.state.user}
                        setUser={this.setUser}
                        lang={this.state.lang}
                      ></Profile>
                    )}
                  />
                  <Route
                    exact
                    path="/privacy"
                    render={(props) => <Privacy {...props}></Privacy>}
                  />
                  <Route
                    exact
                    path="/category/:id"
                    render={(props) => (
                      <Category {...props} lang={this.state.lang}></Category>
                    )}
                  />
                  <Route
                    exact
                    path="/unsub"
                    render={(props) => (
                      <Unsubscribe setUser={this.setUser}></Unsubscribe>
                    )}
                  />
                </div>
              </div>
              <div className="row align-items-end">
                <div className="col p-0">
                  <Footer />
                </div>
              </div>
            </div>
            <ScrollToTop />
          </Router>
        }
      </div>
    );
  }
}

export default App;
