import React, { useState, useReducer, useEffect } from "react";
import i18n from "../Locales";

import axios from "axios";
import {
  SUBSCRIPTION_URL,
  SUBSCRIPTION_PROJECT_ID,
  SUBSCRIPTION_SECRET_KEY,
  KNECT_SUBSCRIPTION_URL,
  KNECT_SUBSCRIPTION_SERVICE_ID,
  KNECT_SUBSCRIPTION_HEADER,
  TELCO,
  KNECT_SUBSCRIPTION_URL_VIRGIN,
  PARTNER_ID,
  CAMPAIGN_ID,
  getErrorObj,
  getNumbervalidObj,
} from "../Constants";

const Login = (props) => {
  const [code] = useState("966");
  const [number, setnumber] = useState("");
  const [telco, settelco] = useState("56");
  const [pin, setpin] = useState("");
  const [showPin, setshowPin] = useState(false);
  const [errMesg, seterrMsg] = useState("");
  const [btnText, setbtnText] = useState(i18n.continue);
  const [userID, setUserID] = useState(null);

  //let regexString = /^03\d{9}$|^3\d{9}$|^923\d{9}$|^0923\d{9}$/;
  let regexString = "";

  // const getUser = async (telco, code, number) => {
  //   seterrMsg("");
  //   try {
  //     // If the user exists user is returned other wise user is returned after creating the user. Behaviour of api used below
  //     //const url = `${SUBSCRIPTION_URL}/api/user/find_sub_by_phone?web_user=1&telco=${telco}&project_id=${SUBSCRIPTION_PROJECT_ID}&secret_key=${SUBSCRIPTION_SECRET_KEY}&phone=${number}`;
  //     let resp = await fetch(url);
  //     let respObj = await resp.json();
  //     localStorage.setItem("msisdn", false);

  //     if (respObj.status === 1) {
  //       props.setUser(respObj.user, true);
  //       setbtnText(i18n.continue);
  //     } else {
  //       seterrMsg(`Sorry something went wrong. Please try again.`);
  //     }
  //   } catch (e) {
  //     console.log("Error in getting user", e.message);
  //     seterrMsg(i18n.somethingwrongmsg);
  //     setbtnText(i18n.subscribe);
  //   }
  // };
  const getUser = async (telco, code, number) => {
    seterrMsg("");
    try {
      // If the user exists user is returned other wise user is returned after creating the user. Behaviour of api used below
      // const url = `${DRAMATIME_URL}/api/user/find_sub_by_phone?web_user=1&telco=${telco}&project_id=${DT_PROJECT_ID}&secret_key=${DT_SECRET_KEY}&phone=${number}`;

      let url = `${KNECT_SUBSCRIPTION_URL}/api/v1/checkUserStatus?msisdn=${`${number}`}`;
      let resp = await fetch(url, {
        method: "GET",
        headers: KNECT_SUBSCRIPTION_HEADER,
      });

      let respObj = await resp.json();
      console.log(respObj.data);

      if (respObj.data.status === 1) {
        localStorage.setItem("number", number);
        props.setUser(respObj.data, true);
        setbtnText(i18n.continue);
      } else {
        seterrMsg(`Sorry something went wrong. Please try again.`);
      }
    } catch (e) {
      console.log("Error in getting user", e.message);
      seterrMsg(i18n.somethingwrongmsg);
      setbtnText(i18n.subscribe);
    }
  };

  const checkORCreateUser = async (telco, code, number) => {
    seterrMsg("");
    if (!number || number.trim().length !== 12 || !validateNumberSA(number)) {
      seterrMsg(i18n.validnumber);
      setshowPin(false);
      setbtnText(i18n.continue);
      return;
    }

    if (!telco) {
      seterrMsg(i18n.selectcarrier);
      setshowPin(false);
      setbtnText(i18n.continue);
      return;
    }

    try {
      setbtnText(i18n.pleasewait);
      let url = `${KNECT_SUBSCRIPTION_URL}/api/v1/checkSubscriptionStatus?msisdn=${`${number.slice(
        0,
        number.length
      )}`}&serviceId=${telco}`;

      let resp = await fetch(url, {
        method: "GET",
        headers: KNECT_SUBSCRIPTION_HEADER,
      });

      let respObj = await resp.json();

      //success message true with code 114 if testing number == 966111111111

      if (number === "966111111111") {
        respObj.success = true;

        respObj.response_code = 114;
      }
      // validation();
      if (
        respObj.success &&
        (respObj.response_code === 114 || respObj.response_code === 110)
      ) {
        localStorage.setItem("telco", telco);
        getUser(telco, code, number);
      } else {
        localStorage.setItem("telco", telco);
        sendPin(telco, code, number);
      }
    } catch (e) {
      console.log("Error in check user status", e.message);
      seterrMsg(i18n.somethingwrongmsg);
      setbtnText(i18n.continue);
    }
  };
  const checkSubscriptionStatus = async (number) => {
    seterrMsg("");
    try {
      let url = `${KNECT_SUBSCRIPTION_URL}/api/v1/checkSubscriptionStatusMSISDN?msisdn=${`${number.slice(
        0,
        number.length
      )}`}&productId=4`;

      let resp = await fetch(url, {
        method: "GET",
        headers: KNECT_SUBSCRIPTION_HEADER,
      });

      let respObj = await resp.json();
      console.log("user Status ->", respObj.success, respObj.response_code);

      // validation();
      if (
        respObj.success &&
        (respObj.response_code === 114 || respObj.response_code === 110)
      ) {
        console.log(1);
        getUser(telco, code, number);
      } else {
        console.log(2);
        sendPin(telco, code, number);
      }
    } catch (e) {
      console.log("Error in check user status", e.message);
      seterrMsg(i18n.somethingwrongmsg);
    }
  };
  //ecib
  const sendPin = async (telco, code, number) => {
    localStorage.setItem("telco", telco);
    seterrMsg("");
    try {
      // const url = `${SUBSCRIPTION_URL}/telco/${telco}/send_pin?web_user=1&telco=${telco}&project_id=${SUBSCRIPTION_PROJECT_ID}&secret_key=${SUBSCRIPTION_SECRET_KEY}&msisdn=${number}&user_id=${userID}`
      const url = `${KNECT_SUBSCRIPTION_URL}/api/v1/genAuthCode`;
      // let resp = await fetch(url);
      let resp = await fetch(url, {
        method: "POST",
        headers: KNECT_SUBSCRIPTION_HEADER,
        body: JSON.stringify({
          msisdn: `${number.slice(0, number.length)}`,
          serviceId: parseInt(telco),
        }),
      });

      let respObj = await resp.json();
      console.log("Send pin resp ->", respObj);
      // if (respObj.status === 1) {
      if (respObj.success) {
        setshowPin(true);
        setbtnText(i18n.subscribe);
      } else {
        seterrMsg(i18n.sendpinerrmesg);
        setshowPin(false);
        setbtnText(i18n.continue);
      }
    } catch (e) {
      console.log("Error in send pin", e.message);
      seterrMsg(i18n.somethingwrongmsg);
      setbtnText(i18n.continue);
    }
  };

  const confirmPin = async (telco, code, number, pin, userID) => {
    console.log("confirmPin");
    console.log(number);
    seterrMsg("");
    if (!pin) {
      seterrMsg(i18n.enterpinmsg);
      setshowPin(true);
      setbtnText(i18n.subscribe);
      return;
    }
    try {
      setbtnText(i18n.pleasewait);
      const url = `${KNECT_SUBSCRIPTION_URL}/api/v1/validateAuthCode`;
      // let resp = await fetch(url);
      let resp = await fetch(url, {
        method: "POST",
        headers: KNECT_SUBSCRIPTION_HEADER,
        body: JSON.stringify({
          msisdn: `${number.slice(0, number.length)}`,
          serviceId: parseInt(telco),
          pinCode: pin,
        }),
      });
      let respObj = await resp.json();
      console.log("confirm pin resp", respObj);
      //   if (respObj.status === 1 && respObj.user.subscribe_status === 1) {
      if (respObj.success && respObj.response_code === 0) {
        getUser(telco, code, number);
        // console.log(`Success`);
      } else {
        seterrMsg(
          respObj.response_code === 103
            ? `Invalid Pin. Please Enter a valid Pin.`
            : i18n.confirmpinerrmsg
        );
        setbtnText(i18n.subscribe);
        // setshowPin(false);
      }

      // setbtnText(i18n.subscribe);
    } catch (e) {
      console.log("Error in confirm pin", e.message);
      seterrMsg(i18n.somethingwrongmsg);
      setbtnText(i18n.subscribe);
    }
  };

  const validateNumberSA = (number) => {
    //console.log(number);
    if (number.trim().length === 0) {
      return getErrorObj("Please enter Valid number");
    }
    let msisdn = number;

    //by pass if testing number == 966111111111

    if (number !== "966111111111") {
      if (msisdn.substring(0, 7) === "0096605") {
        msisdn = `05${msisdn.substring(7)}`;
      } else if (msisdn.substring(0, 6) === "009665") {
        msisdn = `05${msisdn.substring(6)}`;
      } else if (msisdn.substring(0, 5) === "09665") {
        msisdn = `05${msisdn.substring(5)}`;
      } else if (msisdn.substring(0, 5) === "96605") {
        msisdn = `05${msisdn.substring(5)}`;
      } else if (msisdn.substring(0, 4) === "9665") {
        msisdn = `05${msisdn.substring(4)}`;
      } else if (msisdn.substring(0, 3) === "905") {
        msisdn = `05${msisdn.substring(3)}`;
      } else if (msisdn.substring(0, 2) === "05") {
        msisdn = `05${msisdn.substring(2)}`;
      } else if (msisdn.substring(0, 1) === "5") {
        msisdn = `05${msisdn.substring(1)}`;
      } else {
        return getErrorObj("Please enter Valid number");
      }
    }
    let numero = msisdn.substring(0, 3);
    let initial = msisdn.substring(0, 3);
    let slicemsisdn = msisdn.substring(1, msisdn.length);

    //Check for testing number 966111111111
    if (number !== "966111111111") msisdn = `966${slicemsisdn}`;
    else msisdn = `9${slicemsisdn}`;

    regexString = msisdn;
    //console.log(msisdn);

    if (msisdn.length !== 12) {
      return getErrorObj("Please enter Valid number");
    } else {
      return getNumbervalidObj(initial, numero, msisdn);
    }
  };

  const getNumberFromHeader = async (url) => {
    try {
      let resp = await fetch(url);
      resp = await resp.json();
      return resp;
    } catch (error) {
      console.error("Error in get number from header", error.message);
    }
  };

  const getValidNumber = async (operator) => {
    const url = `http://knect.khaleef.com:9001/api/subscriptions/checkPkOperator`;
    return axios.get(url, {
      params: {
        msisdn: `${operator}`,
      },
    });
  };

  const getIP = async () => {
    try {
      const URL = `http://www.geoplugin.net/json.gp`;
      let resp = await fetch(URL);
      resp = await resp.json();
      return resp;
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div>
      <div
        className=""
        style={{
          maxWidth: "100%",
          width: "100%",
          margin: "0 auto -3rem",
          backgroundColor: "#1c1d26",
        }}
      >
        <div className="bg-top">
          <div className="bg-bottom">
            <div style={{ maxWidth: "400px", width: "80%", margin: "0 auto" }}>
              {!showPin && (
                <form>
                  <div className="row">
                    <div className="col">
                      <h5 className="subscription-title">
                        {i18n.subscription}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <img
                        src="/img-subscription.png"
                        alt=""
                        className="subscription-img"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-2 formGridState">
                      <img
                        src="/sa.png"
                        width="30"
                        alt=""
                        style={{ paddingTop: "16px" }}
                      />
                    </div>
                    <div className="col formGridAddress1 pl-1">
                      <input
                        className="form-control"
                        type="text"
                        placeholder={i18n.mobilenumber}
                        maxLength="12"
                        onChange={(e) => {
                          setnumber(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        borderBottom: "1px solid rgba(134,140,154,0.6)",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    ></div>
                  </div>
                  <div className="row">
                    <div className=" col form-group carrier pr-0">
                      <select
                        className="dt-select w-100 "
                        onChange={(e) => {
                          settelco(e.target.value);
                        }}
                      >
                        <option value="56"> Virgin </option>
                        <option value="57"> Mobily </option>
                        <option value="58"> Red Bull Mobile </option>
                      </select>
                    </div>
                    <div
                      style={{
                        borderBottom: "1px solid rgba(134,140,154,0.6)",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    ></div>
                  </div>
                  {telco === "56" && (
                    <div className="row">
                      <div className="col">
                        <p className="text-center">
                          {" "}
                          {i18n.telenorpricepoints}{" "}
                        </p>
                      </div>
                    </div>
                  )}

                  {telco === "57" && (
                    <div className="row">
                      <div className="col">
                        <p className="text-center"> {i18n.mobilyDisclaimer} </p>
                      </div>
                    </div>
                  )}

                  {telco === "58" && (
                    <div className="row">
                      <div className="col">
                        <p className="text-center"> {i18n.redbullDisclaimer} </p>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col">
                      <button
                        variant="primary"
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          checkORCreateUser(telco, code, number);
                        }}
                        className="btn-next btn btn-primary"
                      >
                        {btnText}
                      </button>
                    </div>
                  </div>
                </form>
              )}

              {showPin && (
                <form>
                  <div className="row">
                    <div className="col">
                      <h5 className="subscription-title">
                        {i18n.subscription}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <img
                        src="/img-subscription.png"
                        alt=""
                        className="subscription-img"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col ">
                      <p> {i18n.sendpininfo} </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col formGridAddress1">
                      <input
                        style={{ width: "100%" }}
                        className="form-control"
                        placeholder={i18n.enterpin}
                        onChange={(e) => {
                          setpin(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        borderBottom: "1px solid rgba(134,140,154,0.6)",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    ></div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <button
                        variant="primary"
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          confirmPin(telco, code, number, pin, userID);
                        }}
                        className="btn-next btn btn-primary"
                      >
                        {btnText}
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-center mt-5">
                      <p> {i18n.dontrecievepin} </p>
                      <h6
                        onClick={() => {
                          setshowPin(false);
                          setbtnText(i18n.continue);
                        }}
                        className="text-light cursor-pointer"
                      >
                        {" "}
                        {i18n.resendpin} <i className="dt-chevron-right"></i>
                      </h6>
                    </div>
                  </div>
                </form>
              )}
              <div className="text-center mt-3 text-danger text-bold">
                {errMesg}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
