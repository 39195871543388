import React, { useState } from "react";
import i18n from "../Locales";

import {
  SUBSCRIPTION_URL,
  SUBSCRIPTION_PROJECT_ID,
  SUBSCRIPTION_SECRET_KEY,
  TELCO,
  getErrorObj,
  getNumbervalidObj,
} from "../Constants";

const Unsubscribe = (props) => {
  const [code] = useState("92");
  const [number, setnumber] = useState("");
  const [telco, settelco] = useState(TELCO);
  const [pin, setpin] = useState("");
  const [showPin, setshowPin] = useState(false);
  const [errMesg, seterrMsg] = useState("");
  const [btnText, setbtnText] = useState(i18n.unsubscribeBTN);
  const [userID, setUserID] = useState(null);

  //let regexString = /^03\d{9}$|^3\d{9}$|^923\d{9}$|^0923\d{9}$/;
  let regexString = "";

  const unsubscribeUser = async () => {
    seterrMsg("");
    // if (
    //   !number ||
    //   number.trim().length !== 11 ||
    //   !validatePhone(number, regexString)
    // ) {
    if (!number || number.trim().length !== 12 || !validateNumberSA(number)) {
      seterrMsg(i18n.validnumber);
      setbtnText(i18n.continue);
      return;
    }

    let phone = number;
    let url = `${SUBSCRIPTION_URL}/telco/${telco}/unsubscribe?msisdn=${phone}&telco=${telco}&project_id=${SUBSCRIPTION_PROJECT_ID}&secret_key=${SUBSCRIPTION_SECRET_KEY}&web_user=1`;
    let resp = await fetch(url);
    let respObj = await resp.json();

    if (respObj.status === 1) {
      props.setUser({}, false, true);
    }
  };

  //   const validatePhone = (phoneNumber, regexString) => {
  //     if (!phoneNumber) {
  //       return false;
  //     } else if (!regexString.test(phoneNumber)) {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   };

  const validateNumberSA = (number) => {
    //console.log(number);
    if (number.trim().length === 0) {
      return getErrorObj("Please enter Valid number");
    }
    let msisdn = number;
    if (msisdn.substring(0, 7) === "0096605") {
      msisdn = `05${msisdn.substring(7)}`;
    } else if (msisdn.substring(0, 6) === "009665") {
      msisdn = `05${msisdn.substring(6)}`;
    } else if (msisdn.substring(0, 5) === "09665") {
      msisdn = `05${msisdn.substring(5)}`;
    } else if (msisdn.substring(0, 5) === "96605") {
      msisdn = `05${msisdn.substring(5)}`;
    } else if (msisdn.substring(0, 4) === "9665") {
      msisdn = `05${msisdn.substring(4)}`;
    } else if (msisdn.substring(0, 3) === "905") {
      msisdn = `05${msisdn.substring(3)}`;
    } else if (msisdn.substring(0, 2) === "05") {
      msisdn = `05${msisdn.substring(2)}`;
    } else if (msisdn.substring(0, 1) === "5") {
      msisdn = `05${msisdn.substring(1)}`;
    } else {
      return getErrorObj("Please enter Valid number");
    }
    let numero = msisdn.substring(0, 3);
    let initial = msisdn.substring(0, 3);
    let slicemsisdn = msisdn.substring(1, msisdn.length);
    msisdn = `966${slicemsisdn}`;
    regexString = msisdn;
    console.log(msisdn);
    console.log(regexString);

    if (msisdn.length !== 12) {
      return getErrorObj("Please enter Valid number");
    } else {
      return getNumbervalidObj(initial, numero, msisdn);
    }
  };

  return (
    <div>
      <div
        className=""
        style={{
          maxWidth: "100%",
          width: "100%",
          margin: "0 auto -3rem",
          backgroundColor: "#1c1d26",
        }}
      >
        <div className="bg-top">
          <div className="bg-bottom">
            <div style={{ maxWidth: "400px", width: "80%", margin: "0 auto" }}>
              {!showPin && (
                <form>
                  <div className="row">
                    <div className="col">
                      <h5 className="subscription-title">
                        {i18n.unsubscribeBTN}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <img
                        src="/img-subscription.png"
                        alt=""
                        className="subscription-img"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-2 formGridState">
                      <img
                        src="/pak.jpg"
                        width="30"
                        alt=""
                        style={{ paddingTop: "16px" }}
                      />
                    </div>
                    <div className="col formGridAddress1 pl-1">
                      <input
                        className="form-control"
                        placeholder={i18n.mobilenumber}
                        maxLength="11"
                        onChange={(e) => {
                          setnumber(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      style={{
                        borderBottom: "1px solid rgba(134,140,154,0.6)",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    ></div>
                  </div>
                  <div className="row">
                    <div className=" col form-group carrier pr-0">
                      <select
                        className="dt-select w-100"
                        onChange={(e) => {
                          settelco(e.target.value);
                        }}
                      >
                        <option value="telenor"> Telenor </option>
                      </select>
                    </div>
                    <div
                      style={{
                        borderBottom: "1px solid rgba(134,140,154,0.6)",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    ></div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <button
                        variant="primary"
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          unsubscribeUser(telco, code, number);
                        }}
                        className="btn-next btn btn-primary"
                      >
                        {btnText}
                      </button>
                    </div>
                  </div>
                </form>
              )}
              <div className="text-center mt-3 text-danger text-bold">
                {errMesg}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Unsubscribe;
